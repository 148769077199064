<!--
  * Copyright 2023; Réal Demers.
  *
  * Licensed under the Apache License, Version 2.0 (the "License");
  * you may not use this file except in compliance with the License.
  * You may obtain a copy of the License at
  *
  *    http://www.apache.org/licenses/LICENSE-2.0
  *
  * Unless required by applicable law or agreed to in writing, software
  * distributed under the License is distributed on an "AS IS" BASIS,
  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  * See the License for the specific language governing permissions and
  * limitations under the License.
  -->
<template>
    <v-app>
      <v-navigation-drawer v-if="isUserLoggedIn" v-model="isDrawer" :mini-variant="miniVariant" :clipped="clipped" fixed app>
            <v-list>
                <v-list-item v-for="(menu, i) in menus" :key="i" :title="$t(menu.title)" :to="menu.to" router :exact="true">
                    <v-list-item-action>
                        <v-icon>{{menu.icon}}</v-icon>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar :clipped-left="clipped" fixed app>
            <v-app-bar-nav-icon :disabled=!isUserLoggedIn @click="swapDrawer()"/>
            <v-toolbar-title v-text="title"/>
            <v-spacer/>
            <v-menu offset-y>
                <template v-slot:activator="{props}">
                    <v-btn :flat="true" v-bind="props">
                        <v-icon left>mdi-translate-variant</v-icon>
                        <span class="mx-4 text-capitalize">{{ currentLocale }}</span>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(locale, i) in locales" :key=i @click="changeLocale(locale)">
                        <v-list-item-title v-text="(locale).name"/>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn icon :disabled=!isUserLoggedIn @click="doLogout()">
                <v-icon>mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>
        <v-main>
            <v-container>
              <v-row v-if="!isUserLoggedIn" align="center" justify="center">
                <v-card max-width="500">
                  <v-alert v-if=isErrorLogin color="error" icon="$error"
                           :title="$t('login.title')" :text="$t('login.alert.text')"/>
                  <authenticator :sign-up-attributes="['email']">
<!--                  <template v-slot="{ user, signOut }">-->
<!--                      &lt;!&ndash;          Uncomment if you want to display the username of the user logged in, not necessary since we redirect after login&ndash;&gt;-->
<!--                      &lt;!&ndash;          <h1>Welcome {{ user.username }}!</h1>&ndash;&gt;-->
<!--                    </template>-->
                  </authenticator>
                </v-card>
              </v-row>
              <slot v-if="isUserLoggedIn"/>
            </v-container>
        </v-main>
        <v-footer :absolute="!fixed" :app="true">
            <span>Copyright &copy; {{new Date().getFullYear()}}</span>
        </v-footer>
    </v-app>
</template>

<script setup lang="ts">

    import type Menu from "~/types/Menu";

    import {Amplify} from 'aws-amplify';
    import authentificationService from "~/services/AuthentificationService";
    import { Authenticator, useAuthenticator } from '@aws-amplify/ui-vue';

    Amplify.configure({
      Auth: {
        Cognito: {
          // @ts-ignore
          userPoolId: window.__COGNITO__.userPoolId,
          // @ts-ignore
          userPoolClientId: window.__COGNITO__.userPoolClientId
        }
      },
    });

    import "@aws-amplify/ui-vue/styles.css";

    const auth = useAuthenticator();
    const isErrorLogin = ref<boolean>(false);

    function navigateToHome(): any {
      return navigateTo("/");
    }

    const { authStatus, user } = toRefs(useAuthenticator());


    // If you want to use cookie storage in case for the auth token
    // import {CookieStorage} from "@aws-amplify/core";
    // import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito';
    // cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({sameSite: "strict"}));

    const title:string = "org.rd.fullstack.springboot-nuxt";
    const menus:Menu[] = [
        { icon: "mdi-home",            title: "layout.home",       to: "/" },
        { icon: "mdi-book",            title: "layout.books",      to: "/books" },
        { icon: "mdi-developer-board", title: "layout.scratchpad", to: "/scratchpad/ui" },
        { icon: "mdi-copyright",       title: "layout.about",      to: "/about" }
    ];

    const clipped     = ref<boolean>(false);
    const drawer      = ref<boolean>(false);
    const fixed       = ref<boolean>(false);
    const miniVariant = ref<boolean>(false);

    const { locale, locales, setLocale } = useI18n();

    function changeLocale(locale:any): void {
        setLocale(locale.code);
    }

    async function doLogout(): Promise<any> {
      await authentificationService.doLogout()
      drawer.value = false;
      return navigateTo("/");
    }

    function swapDrawer(): void {
        drawer.value = !drawer.value;
    }

    const isDrawer = computed<boolean>(() => {
        return drawer.value;
    })

    const isUserLoggedIn = computed<boolean>(() => {
        return authStatus.value === "authenticated";
    })

    const currentLocale = computed<string|undefined>(() => {
        const localeArray:any[] = locales.value as any[];
        const lo:any|undefined = localeArray.find(i => i.code === locale.value);
        return lo?.name;
    })

</script>

<style scoped>
</style>

<style>
@media  {
  :root {
    --amplify-colors-red-10: hsl(0, 100%, 15%);
    --amplify-colors-red-20: hsl(0, 100%, 20%);
    --amplify-colors-red-40: hsl(0, 95%, 30%);
    --amplify-colors-red-80: hsl(0, 75%, 75%);
    --amplify-colors-red-90: hsl(0, 75%, 85%);
    --amplify-colors-red-100: hsl(0, 75%, 95%);
    --amplify-colors-orange-10: hsl(30, 100%, 15%);
    --amplify-colors-orange-20: hsl(30, 100%, 20%);
    --amplify-colors-orange-40: hsl(30, 95%, 30%);
    --amplify-colors-orange-80: hsl(30, 75%, 75%);
    --amplify-colors-orange-90: hsl(30, 75%, 85%);
    --amplify-colors-orange-100: hsl(30, 75%, 95%);
    --amplify-colors-yellow-10: hsl(60, 100%, 15%);
    --amplify-colors-yellow-20: hsl(60, 100%, 20%);
    --amplify-colors-yellow-40: hsl(60, 95%, 30%);
    --amplify-colors-yellow-80: hsl(60, 75%, 75%);
    --amplify-colors-yellow-90: hsl(60, 75%, 85%);
    --amplify-colors-yellow-100: hsl(60, 75%, 95%);
    --amplify-colors-green-10: hsl(130, 22%, 23%);
    --amplify-colors-green-20: hsl(130, 27%, 29%);
    --amplify-colors-green-40: hsl(130, 33%, 37%);
    --amplify-colors-green-80: hsl(130, 44%, 63%);
    --amplify-colors-green-90: hsl(130, 60%, 90%);
    --amplify-colors-green-100: hsl(130, 60%, 95%);
    --amplify-colors-teal-10: hsl(190, 100%, 15%);
    --amplify-colors-teal-20: hsl(190, 100%, 20%);
    --amplify-colors-teal-40: hsl(190, 95%, 30%);
    --amplify-colors-teal-80: #4CAF50;
    --amplify-colors-teal-90: hsl(190, 75%, 85%);
    --amplify-colors-teal-100: hsl(190, 75%, 95%);
    --amplify-colors-blue-10: hsl(220, 100%, 15%);
    --amplify-colors-blue-20: hsl(220, 100%, 20%);
    --amplify-colors-blue-40: hsl(220, 95%, 30%);
    --amplify-colors-blue-80: hsl(220, 70%, 70%);
    --amplify-colors-blue-90: hsl(220, 85%, 85%);
    --amplify-colors-blue-100: hsl(220, 95%, 95%);
    --amplify-colors-purple-10: hsl(300, 100%, 15%);
    --amplify-colors-purple-20: hsl(300, 100%, 20%);
    --amplify-colors-purple-40: hsl(300, 95%, 30%);
    --amplify-colors-purple-80: hsl(300, 70%, 70%);
    --amplify-colors-purple-90: hsl(300, 85%, 85%);
    --amplify-colors-purple-100: hsl(300, 95%, 95%);
    --amplify-colors-pink-10: hsl(340, 100%, 15%);
    --amplify-colors-pink-20: hsl(340, 100%, 20%);
    --amplify-colors-pink-40: hsl(340, 95%, 30%);
    --amplify-colors-pink-80: hsl(340, 70%, 70%);
    --amplify-colors-pink-90: hsl(340, 90%, 85%);
    --amplify-colors-pink-100: hsl(340, 95%, 95%);
    --amplify-colors-neutral-10: hsl(210, 50%, 10%);
    --amplify-colors-neutral-20: hsl(210, 25%, 25%);
    --amplify-colors-neutral-40: hsl(210, 10%, 40%);
    --amplify-colors-neutral-80: hsl(210, 5%, 87%);
    --amplify-colors-neutral-90: hsl(210, 5%, 94%);
    --amplify-colors-neutral-100: hsl(210, 5%, 98%);
    --amplify-colors-black: #fff;
    --amplify-colors-white: #212121;
    --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
    --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
    --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
    --amplify-colors-overlay-10: hsla(0, 0%, 100%, 0.1);
    --amplify-colors-overlay-20: hsla(0, 0%, 100%, 0.2);
    --amplify-colors-overlay-30: hsla(0, 0%, 100%, 0.3);
    --amplify-colors-overlay-40: hsla(0, 0%, 100%, 0.4);
    --amplify-colors-overlay-50: hsla(0, 0%, 100%, 0.5);
    --amplify-colors-overlay-60: hsla(0, 0%, 100%, 0.6);
    --amplify-colors-overlay-70: hsla(0, 0%, 100%, 0.7);
    --amplify-colors-overlay-80: hsla(0, 0%, 100%, 0.8);
    --amplify-colors-overlay-90: hsla(0, 0%, 100%, 0.9);
  }
}</style>
